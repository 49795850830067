import React, { useEffect, useState, useRef, useCallback } from 'react';
import Card from "../../../components/shared/components/Card/Card";
import SettingService from "../../../services/Setting.service"
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
const Settings = () => {
  const [setting,setSetting] = useState();
  const [loader,setLoader] = useState(false);
  const api = async () => {
    const s = await SettingService.getParticular();
    if(s?.data?.result){
      setSetting(s.data.result)
    }
  }
  useEffect(()=>{
    api()
  },[])
  const formik = useFormik({
    initialValues:{
      minimum:setting?.withdraw?.minimum || 0
    },
    enableReinitialize:true,
    onSubmit:async (values) => {
      console.log({values})
      setLoader(true)
      await SettingService.edit(setting._id,{
        withdraw:{
          minimum:values.minimum
        }
      })
      
      setLoader(false)
      toast.success("Saved")
      api()

    }
  })
  return (
    <div className='flex flex-col gap-4'>
      <div className='text-3xl font-semibold'>
        Settings
      </div>

      <Card>
        <div className='w-full flex justify-end pb-4 gap-4'>
          <Box className="flex gap-12 w-full items-center">
            <Box>Minimum Withdraw Amount:</Box>
            <Box>
              <TextField name='minimum' type='number' onChange={formik.handleChange} value={formik.values.minimum} size='small' />
            </Box>
            
           
          </Box>
         
        </div>
        <Box>
              <Button variant='contained' className="flex gap-2" disabled={loader} onClick={formik.handleSubmit} >
               {loader && <CircularProgress size={20} className='mr-2' />}
                 Save
                </Button>
            </Box>
      </Card>
    </div>
  );
};

export default Settings;
