import React, {Fragment} from 'react';
import {TextField} from "@mui/material";
import {useFormik} from "formik";
// import {sendForgotPasswordMail} from "../../../core/services/User/forgotPasswordService";
import {useNavigate} from "react-router-dom";

const Forgotpassword = () => {
    const navigate = useNavigate();
    const forgotPasswordForm = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: (values) => {
            console.log('email', values);
            // sendForgotPasswordMail(forgotPasswordForm.values.email).then((res) => {
            //     res && navigate('/login/mail-sent');
            // });
        }
    })
    return (
        <Fragment>
            <div className="py-5">
                <h1 className="text-4xl font-bold">Forgot Password</h1>
                <h3 className="text-lg my-3 text-start text-gray-400 max-w-sm">
                    Please enter your email address and we will end you an email to reset your password.
                </h3>
            </div>
            <form onSubmit={forgotPasswordForm.handleSubmit}>
                <div className="my-5">
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="email"
                        name="email"
                        label="Enter your email"
                        value={forgotPasswordForm.values.email}
                        onChange={forgotPasswordForm.handleChange}
                        onBlur={forgotPasswordForm.handleBlur}
                        helperText={forgotPasswordForm.touched.email && Boolean(forgotPasswordForm.errors.email) ? 'Email is required' : ''}
                        error={forgotPasswordForm.touched.email && Boolean(forgotPasswordForm.errors.email)}
                    />
                </div>
                <button
                    type="submit"
                    className="text-white rounded w-full my-4 uppercase app-button focus:outline-none focus:ring-4
                 focus:ring-gray-300 font-medium text-sm px-5 py-2.5 mr-2 mb-2">
                    Send reset link
                </button>
            </form>
        </Fragment>
    );
};

export default Forgotpassword;