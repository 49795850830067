import React, { useEffect, useState, useRef, useCallback } from 'react';
import Card from "../../../components/shared/components/Card/Card";
import DataGrid from '../../../components/shared/components/DataGridRenderer/DataGrid';
import BankService from '../../../services/Bank.service';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import OrderService from '../../../services/Order.service';
import OfferService from '../../../services/Offer.service';
import Switch from '@mui/material/Switch';
import Editdeletebutton from '../../../components/shared/components/Editdeletebutton/Editdeletebutton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import CloseIcon from "@mui/icons-material/Close";
import * as yup from 'yup';
import uploadService from '../../../services/upload.service';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const Banners = () => {


  const [open, setOpen] = React.useState(false);
  const [showLoader,setShowLoader] = useState(false)
  const handleOpen = () => setOpen(true);
  
  const [picture, setPicture] = useState('');

  const [selectedOffer,setSelectedOffer] = useState(null);
  const [data, setData] = useState([])
  const getOfferDetails = async () => {
    
    const result = await OfferService.get();
    if (result) {
      setData(result.data.result)

    }
  }
  const handleClose = () => {
    setOpen(false)
    setPicture("");
    formik.setValues({
      name:"",
      picture:"",
      withdraw:0,
      trip:0
    })
    setSelectedOffer(null)
  };
  useEffect(() => {
    getOfferDetails()
  }, [])

    const formik = useFormik({
      initialValues:{
        name:selectedOffer ? selectedOffer.name:"",
        picture:selectedOffer ? selectedOffer.picture:"",
        withdraw:selectedOffer ? selectedOffer?.rewardPoints?.withdraw:0,
        trip:selectedOffer ? selectedOffer?.rewardPoints?.trip:0
      },
      enableReinitialize:true,
      validationSchema: yup.object({
        name: yup.string('This field is required').required('This field is required').trim(),
        picture: yup.string('This field is required').required('This field is required').trim(),
        withdraw: yup.number('This field is required').required('This field is required').min(0),
        trip: yup.number('This field is required').required('This field is required').min(0),
    }),
      onSubmit: async (values) => {
        console.log(values)
        setShowLoader(true)
        new Promise((resolve, reject) => {
          if (typeof values.picture !== "string") {
              const response = uploadService.fileUpload(values.picture)
              if (response) {
                  resolve(response)
              } else {
                  reject()
              }
          } else {
              resolve()
          }
      }).then(async (res) => {
        const payload = {
          ...values,
          picture: res?.result ? res?.result?.url : values.picture,
          rewardPoints:{
            withdraw:values.withdraw,
            trip:values.trip
          }
        }
        // console.log({payload})
        if(selectedOffer){
          await OfferService.update(selectedOffer._id,payload)
        }else{
          await OfferService.create(payload);

        }
        getOfferDetails()
        setShowLoader(false)
        handleClose()
      }).catch(err => setShowLoader(false))
      }
    })
    const hospitalImageInputChangeHandler = (event) => {
      console.log(':Image ur;', event.currentTarget.files[0]);
      if (event.currentTarget.files.length !== 0) {
          const file = event.currentTarget.files[0];
          console.log({ file })
          if (file.size > 1024000) {
              formik.setErrors({
                  picture: "File size is exceeding the limit 1mb ",
              });
              // }
          } else {
              formik.handleChange(event);
              formik.setFieldValue("picture", event.currentTarget.files[0] ? event.currentTarget.files[0] : '')
              setPicture((prevState) => {
                  return URL.createObjectURL(event.target.files[0])
              });
          }
      } else {
          formik.setErrors({
              picture: "Picture is required!",
          });
      }
  }

  const HospitalResetImageInput = () => {
      formik.setFieldValue('picture', '');
      setPicture((prevState) => {
          return ''
      });
  }
  const columns = [
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 150,
      // editable: true,
      valueGetter: (value) => new Date(value).toLocaleDateString()
    },
    {
      field: 'picture',
      headerName: 'Picture',
      // type: 'number',
      width: 200,
      // editable: true,
      renderCell: (params) => {
        console.log(params.row.picture);
        return <img className='' crossorigin="anonymous" alt=' ' src={params.row.picture}></img>
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      // type: 'number',
      width: 150,
      // editable: true,
      valueGetter: (value, row) => row.name

    },

    {
      field: 'activeOffer',
      headerName: 'Active',
      width: 200,
      renderCell: (params, row) => <Switch
        disabled={params.row.activeOffer}
        onChange={async (e) => {
          const fakeData = [...data];
          // const index = fakeData.findIndex(i => i._id.toString() == params.row._id.toString());
          for (let i = 0; i < fakeData.length; i++) {
            if (fakeData[i]._id.toString() == params.row._id.toString()) {
              fakeData[i].activeOffer = e.target.checked;
            } else {
              fakeData[i].activeOffer = false

            }
          }
          setData([...fakeData])
          await OfferService.changeStatus(params.row._id, { activeOffer: e.target.checked })

        }}
        checked={params.row.activeOffer} />
    },
    {
      field: 'withDrawPoints',
      headerName: 'Withdraw',
      // type: 'number',
      width: 150,
      // editable: true,
      valueGetter: (value, row) => row.rewardPoints?.withdraw
    },
    {
      field: 'tripPoints',
      headerName: 'Trips',
      // type: 'number',
      width: 150,
      // editable: true,
      valueGetter: (value, row) => row.rewardPoints?.trip
    },
    {
      field: 'action',
      headerName: 'Action',
      // type: 'number',
      width: 200,
      // editable: true,
      renderCell: (params) => <Editdeletebutton
        data={params.row}
        editClicked={(e) => {
          console.log({edit:params.row})
          setSelectedOffer(params.row)
          setPicture(params.row.picture)
          handleOpen()
        }}
        deleteClicked={async (e) => {
          try {
            await OfferService.delete(params.row._id);
            getOfferDetails()
          } catch (error) {
            
          }

        }}
      />
    },
  ];

  return (
    <div className='flex flex-col gap-4'>
      <div className='text-3xl font-semibold'>
        Banners
      </div>

      <Card>
        <div className='w-full flex justify-end pb-4 gap-4'>
          <Button variant='contained' onClick={handleOpen}>
            + Add New
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
          <div className='flex flex-col gap-4'>
          <TextField
                fullWidth
                variant="outlined"
                id="name"
                name="name"
                label="Enter Name"
                helperText={formik.touched.name && Boolean(formik.errors.name) ? formik.errors.name : ''}
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="withdraw"
                type="number"
                name="withdraw"
                label="Enter withdraw points"
                helperText={formik.touched.withdraw && Boolean(formik.errors.withdraw) ? formik.errors.withdraw : ''}
                value={formik.values.withdraw}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.withdraw && Boolean(formik.errors.withdraw)}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="trip"
                type="number"
                name="trip"
                label="Enter trip points"
                helperText={formik.touched.trip && Boolean(formik.errors.trip) ? formik.errors.trip : ''}
                value={formik.values.trip}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.trip && Boolean(formik.errors.trip)}
              />
              <div>
              <div className="flex flex-col justify-center items-center max-w-full">
                                <label className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                 ${formik.errors.picture
                                        ? 'border-[#d32f2f]' : 'border-gray-300'}
                         border-dashed cursor-pointer`}>
                                    <input
                                        id="dropzone-file"
                                        name="picture"
                                        disabled={picture !== ''}
                                        accept="image/png, image/jpeg, image/jpg, image/webp"
                                        onBlur={formik.handleBlur}
                                        onChange={hospitalImageInputChangeHandler}
                                        type="file"
                                        className="hidden" />
                                    {picture !== '' ? (
                                        <div className="relative w-24 h-24">
                                            <img id="blah" src={picture}
                                            crossorigin="anonymous"
                                                className="shadow w-full object-fill"
                                                alt="your" />
                                            <CloseIcon onClick={HospitalResetImageInput}
                                                className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold" />
                                        </div>
                                    ) : (
                                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                            <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400"
                                                fill="none"
                                                stroke="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                            </svg>
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                                className="font-semibold">Click to upload picture</span> or
                                                drag
                                                and drop</p>
                                            <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG,
                                                JPG or GIF
                                                (MAX.
                                                800x400px)</p>
                                        </div>
                                    )}
                                </label>
                            </div>
                            <div
                                className={`text-[#d32f2f] my-2 text-sm ml-4 ${formik.errors.picture ? '' : 'hidden'}`}>
                                {formik.errors.picture}
                            </div>
              </div>
              <Button onClick={formik.handleSubmit} variant='contained'>{selectedOffer ?"Update" : "Submit"}</Button>
          </div>
            </Box>
          </Modal>

        </div>
        <DataGrid
          rows={data}
          columns={columns}
          sx={{ height: "70vh" }}
          
          pageSizeOptions={[50,100]}
          disableColumnSelector
          disableRowSelectionOnClick
          getRowId={(row) => row._id} />

      </Card>
    </div>
  );
};

export default Banners;
