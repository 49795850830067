import React, { useEffect, useState, useRef, useCallback } from 'react';
import Card from "../../../components/shared/components/Card/Card";
import DataGrid from '../../../components/shared/components/DataGridRenderer/DataGrid';
import OrderService from '../../../services/Order.service';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { MenuItem, Select } from '@mui/material';



const Withdraws = () => {

  const [subscription, setSubscription] = useState()
  const gridRef = useRef(null);
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [data, setData] = useState([])
  const getOrderDetails = async (type) => {
    const result = await OrderService.get(type);
    if (result) {
      setData(result.data.result)

    }
  }
  useEffect(() => {
    let type = "WITHDRAW"
    if(value === '2'){
      type = "EARNING"
    }
    getOrderDetails(type)
  }, [value])
  const columns = [
    // { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 150,
      // editable: true,
      valueGetter: (value) => new Date(value).toLocaleDateString()
    },
  
    {
      field: 'paymentId',
      headerName: 'Txn Id',
      // type: 'number',
      width: 150,
      // editable: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      // type: 'number',
      width: 150,
      // editable: true,
      valueGetter: (value, row) => row.user?.name
  
    },
    {
      field: 'email',
      headerName: 'Email',
      // type: 'number',
      width: 300,
      // editable: true,
      valueGetter: (value, row) => row.user?.email
  
    },
    {
      field: 'withDrawPoints',
      headerName: 'Withdraw',
      // type: 'number',
      width: 150,
      // editable: true,
      valueGetter: (value, row) => row.points?.withdraw
    },
    {
      field: 'tripPoints',
      headerName: 'Trips',
      // type: 'number',
      width: 150,
      // editable: true,
      valueGetter: (value, row) => row.points?.trip
    },
    {
      field: 'status',
      headerName: 'Status',
      // type: 'number',
      width: 200,
      // editable: true,
      renderCell:  (params) =>params.row.status === "PENDING" ? <Select className='w-full' value={params.row.status} onChange={async (e)=>{
        const fakeData = [...data];
        const index = fakeData.findIndex(i => i._id.toString() == params.row._id.toString());
        if(index > -1){
          fakeData[index].status = e.target.value;
          setData([...fakeData])
          await OrderService.changeStatus(params.row._id,{status:e.target.value})
        }

      }}>
        {["COMPLETED",'PENDING','FAILED'].map((item)=>{
          return <MenuItem value={item}>{item}</MenuItem>
        })}
      </Select> : params.row.status
    },
  ];
  const earningColumns = [
    // { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 150,
      // editable: true,
      valueGetter: (value) => new Date(value).toLocaleDateString()
    },
  
    {
      field: 'paymentId',
      headerName: 'Txn Id',
      // type: 'number',
      width: 150,
      // editable: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      // type: 'number',
      width: 150,
      // editable: true,
      valueGetter: (value, row) => row.user?.name
  
    },
    {
      field: 'email',
      headerName: 'Email',
      // type: 'number',
      width: 300,
      // editable: true,
      valueGetter: (value, row) => row.user?.email
  
    },
    {
      field: 'offer',
      headerName: 'Offer',
      // type: 'number',
      width: 300,
      // editable: true,
      valueGetter: (value, row) => row.offer?.name
  
    },
    {
      field: 'withDrawPoints',
      headerName: 'Withdraw',
      // type: 'number',
      width: 150,
      // editable: true,
      valueGetter: (value, row) => row.points?.withdraw
    },
    {
      field: 'tripPoints',
      headerName: 'Trips',
      // type: 'number',
      width: 150,
      // editable: true,
      valueGetter: (value, row) => row.points?.trip
    },
    {
      field: 'status',
      headerName: 'Status',
      // type: 'number',
      width: 150,
      // editable: true,
      renderCell: (params) => params.row.status
    },
  ];

  return (
    <div className='flex flex-col gap-4'>
      <div className='text-3xl font-semibold'>
        Withdraws
      </div>

      <Card>


        
            <DataGrid
              rows={data}
              columns={columns}
              sx={{ height: "70vh" }}
             pagination
              pageSizeOptions={[50, 100]}
              disableColumnSelector
              disableRowSelectionOnClick
              getRowId={(row) => row._id} />
         
      </Card>
    </div>
  );
};

export default Withdraws;
