import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';


export default function DataGridComponent({...props}) {
    return (
        <DataGrid
        
          {...props}
        />
    );
  }