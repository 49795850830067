import React, { useContext, useEffect, useState } from "react";

import Sidebar from "../../components/partials/Sidebar";
import Header from "../../components/partials/Header";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import Home from "./Home/Home";
import Users from "./Users/Users";
import Qrcodes from "./Qrcodes/Qrcodes";
import Withdraws from "./Withdraw/Withdraw";
import Banks from "./Banks/Banks";
import Invoices from "./Invoice/Invoice";
import QRScans from "./QRScans/QRScans";
import Banners from "./Banners/Banners";
import Settings from "./Settings/Settings";


function Dashboard(props) {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
    const [refresh,setRefresh] = useState(false);
    const location = useLocation()
    const [sidebarOpen, setSidebarOpen] = useState(isDesktopOrLaptop ? true : false);
    const handleSetSideBar = () => {
      setSidebarOpen(!sidebarOpen);
    };
  
   
  
    return (
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} closeSidebar={setSidebarOpen} setSidebarOpen={handleSetSideBar} refresh={refresh} setRefresh={setRefresh}/>
  
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header
            sidebarOpen={sidebarOpen}
            logoutUser={props.handleLogoutOfUser}
            setSidebarOpen={setSidebarOpen}
          />
  
          <main className="bg-gray-100">
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl min-h-[93vh] mx-auto">
                
              <Routes>
                <Route path="home" element={<Home />} />
                <Route path="users" element={<Users />} />
                <Route path="qrcodes" element={<Qrcodes />} />
                <Route path="banners" element={<Banners />} />
                <Route path="withdraws" element={<Withdraws />} />
                <Route path="qrscans" element={<QRScans />} />
                <Route path="banks" element={<Banks />} />
                <Route path="invoices" element={<Invoices />} />
                <Route path="settings" element={<Settings />} />

                {/* <Route path="" element={<Navigate to="/dashboard/home" />} />
                <Route path="*" element={<Navigate to="/dashboard/home" />} /> */}
              </Routes>
            </div>
          </main>
        </div>
      </div>
    );
  }
  
  export default Dashboard;