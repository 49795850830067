import httpservice from "../config/httpservice";
const {  BASE_URL }=process.env;

class SettingService {
  constructor() {
    this.baseUrl=BASE_URL||"https://api.annexchemical.com";
  }
  getParticular=async (data) => {
        return httpservice.get(this.baseUrl+"/api/settings/single");
  };
  edit=async (id,data) => {
    return httpservice.put(this.baseUrl+"/api/settings/"+id,{...data});
};
}

export default new SettingService();
