import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DashboardIcon from '@mui/icons-material/Dashboard';

function Sidebar(props) {
    const location = useLocation(); const trigger = useRef(null);
    const sidebar = useRef(null);
    const { pathname } = location;
    const pathnameArray = pathname.split('/');
    const [sidebarExpanded, setSidebarExpanded] = useState(true);

    return (
        <div>
            {/* Sidebar backdrop (mobile only) */}
            <div
                className={`fixed inset-0' bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity
             duration-200 opacity-100 `}
                aria-hidden="true"
            ></div>

            {/* Sidebar */}

            <div
                id="sidebar"
                ref={sidebar}
                className={`flex flex-col absolute z-40 left-0 top-0 lg:static  lg:left-auto 
             lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto  scrollbar-thin scrollbar-thumb-sky-200 scrollbar-track-sky-100 w-64 
              2xl:!w-64 shrink-0 bg-white shadow p-4 transition-all duration-200 ease-in-out ${props.sidebarOpen ? '!translate-x-0 ' : '!-translate-x-64 lg:!absolute'}`}
            >
                {/* Sidebar header back arrow icon and logo */}
                <div className="flex justify-start mb-10 pr-3 sm:px-2">
                    {/* Close button */}
                    <button
                        ref={trigger}
                        className=" text-slate-500 hover:text-slate-400"
                        onClick={props.setSidebarOpen}
                        aria-controls="sidebar"
                        aria-expanded={props.sidebarOpen}
                    >
                        <ArrowBackIcon />
                    </button>
                    {/* Logo */}
                    <div className='w-full flex justify-center items-center'>
                        <NavLink end to="/" className="block">
                            {/* <img src="https://i.postimg.cc/y6p5vXtQ/logo-Hospital.png" style={{ height: '70px', width: "auto" }}
                        alt="" /> */}
                            <img src="/companylogotp.png" style={{ height: '70px', width: "auto" }} className="rounded-lg"
                                alt="" />
                        </NavLink>
                    </div>
                </div>
                {/* Links */}
                <div className="space-y-4">
                    {/* Pages group */}
                    <div>
                        <ul className="mt-3">
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('home') ? '!text-sky-600 bg-sky-50' : '!text-gray-600 hover:bg-sky-50'}`}>
                                <NavLink end to="/dashboard/home"
                                    className={`block hover:text-sky-600 truncate transition duration-150`}>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <DashboardIcon />
                                            <span
                                                className="text-sm font-medium ml-3 duration-200">
                                                Dashboard
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="mt-3">
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('banners') ? '!text-sky-600 bg-sky-50' : '!text-gray-600 hover:bg-sky-50'}`}>
                                <NavLink end to="/dashboard/banners"
                                    className={`block hover:text-sky-600 truncate transition duration-150`}>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <DashboardIcon />
                                            <span
                                                className="text-sm font-medium ml-3 duration-200">
                                                Banners
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="mt-3">
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('users') ? '!text-sky-600 bg-sky-50' : '!text-gray-600 hover:bg-sky-50'}`}>
                                <NavLink end to="/dashboard/users"
                                    className={`block hover:text-sky-600 truncate transition duration-150`}>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <DashboardIcon />
                                            <span
                                                className="text-sm font-medium ml-3 duration-200">
                                                Users
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <ul className="mt-3">
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('qrcodes') ? '!text-sky-600 bg-sky-50' : '!text-gray-600 hover:bg-sky-50'}`}>
                                <NavLink end to="/dashboard/qrcodes"
                                    className={`block hover:text-sky-600 truncate transition duration-150`}>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <DashboardIcon />
                                            <span
                                                className="text-sm font-medium ml-3 duration-200">
                                                Qr Codes
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <ul className="mt-3">
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('withdraws') ? '!text-sky-600 bg-sky-50' : '!text-gray-600 hover:bg-sky-50'}`}>
                                <NavLink end to="/dashboard/withdraws"
                                    className={`block hover:text-sky-600 truncate transition duration-150`}>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <DashboardIcon />
                                            <span
                                                className="text-sm font-medium ml-3 duration-200">
                                                Withdraw Requests
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="mt-3">
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('qrscans') ? '!text-sky-600 bg-sky-50' : '!text-gray-600 hover:bg-sky-50'}`}>
                                <NavLink end to="/dashboard/qrscans"
                                    className={`block hover:text-sky-600 truncate transition duration-150`}>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <DashboardIcon />
                                            <span
                                                className="text-sm font-medium ml-3 duration-200">
                                                QR Scans
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="mt-3">
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('banks') ? '!text-sky-600 bg-sky-50' : '!text-gray-600 hover:bg-sky-50'}`}>
                                <NavLink end to="/dashboard/banks"
                                    className={`block hover:text-sky-600 truncate transition duration-150`}>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <DashboardIcon />
                                            <span
                                                className="text-sm font-medium ml-3 duration-200">
                                                Bank Verifications
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="mt-3">
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('settings') ? '!text-sky-600 bg-sky-50' : '!text-gray-600 hover:bg-sky-50'}`}>
                                <NavLink end to="/dashboard/settings"
                                    className={`block hover:text-sky-600 truncate transition duration-150`}>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <DashboardIcon />
                                            <span
                                                className="text-sm font-medium ml-3 duration-200">
                                                Settings
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    {/* <div>
                        <ul className="mt-3">
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('invoices') ? '!text-sky-600 bg-sky-50' : '!text-gray-600 hover:bg-sky-50'}`}>
                                <NavLink end to="/dashboard/invoices"
                                    className={`block hover:text-sky-600 truncate transition duration-150`}>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <DashboardIcon />
                                            <span
                                                className="text-sm font-medium ml-3 duration-200">
                                                Invoices
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div> */}
                </div>

                    {/* Expand / collapse button */}
                    <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
                        <div className="px-3 py-2">
                            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                                <span className="sr-only">Expand / collapse sidebar</span>
                                <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                                    <path className="text-slate-400"
                                        d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                                    <path className="text-slate-600" d="M3 23H1V1h2z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>);
}

            export default Sidebar;
