import React, { useEffect, useState, useRef, useCallback } from 'react';
import Card from "../../../components/shared/components/Card/Card";
import DataGrid from '../../../components/shared/components/DataGridRenderer/DataGrid';
import UserService from '../../../services/User.service';
import OrderService from '../../../services/Order.service';
import SettingService from '../../../services/Setting.service';

const Home = () => {

  const [subscription, setSubscription] = useState()
  const [attributes,setAttributes] = useState({});
  const gridRef = useRef(null);
  const [data, setData] = useState([])
  const getUserData = async () => {
    const result = await UserService.getUsers();
    if (result) {
      setData(result.data.result)

    }
  }
  const [setting,setSetting] = useState()
  const getAttributes = async () => {
    const result = await OrderService.getAttributes();
    if (result) {
      setAttributes(result.data.result)

    }
  }
  const getSetting = async () => {
    const result = await SettingService.getParticular();
    if (result) {
      setSetting(result.data.result)

    }
  }
  
const columns = [
  // { field: '_id', headerName: 'ID', width: 90 },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    // editable: true,
  },

  {
    field: 'phone',
    headerName: 'Phone',
    // type: 'number',
    width: 150,
    // editable: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    // type: 'number',
    width: 300,
    // editable: true,
  },
  {
    field: 'withDrawPoints',
    headerName: 'Withdraw',
    // type: 'number',
    width: 150,
    // editable: true,
    renderCell: (params) => <span className={`${setting?.withdraw?.minimum && (setting?.withdraw?.minimum < params.row.points?.withdraw)  ? "text-green-500" : ""}`}>{params.row.points?.withdraw}</span>
  },
  {
    field: 'tripPoints',
    headerName: 'Trips',
    // type: 'number',
    width: 150,
    // editable: true,
    renderCell: (params) => <span className={`${setting?.withdraw?.minimum && (setting?.withdraw?.minimum < params.row.points?.trip)  ? "text-green-500" : ""}`}>{params.row.points?.trip}</span>
  },
  {
    field: 'lastScanTime',
    headerName: 'Last Scan time',
    // type: 'number',
    width: 300,
    // editable: true,
    valueGetter: (value, row) => row.orders?.[0]?.createdAt ? new Date(row.orders?.[0]?.createdAt).toLocaleString() : "-"
  },
];


  useEffect(() => {
    getUserData()
    getAttributes()
    getSetting()
  }, [])
  return (
    <div className='flex flex-col gap-4'>
      <div className="flex justify-between">
        {/* <div className="order-first">
                    <h1 className="text-3xl font-semibold">Dashboard</h1>
                </div> */}
        {/* <div className="order-last">
                    <Filterbutton text="Filter by plans"/>
                </div> */}
      </div>
      <Card>
        <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10">
          <div
            className="col-span-12 md:col-span-4 shadow  flex flex-col bg-orange-400 rounded-xl text-white p-3 px-10">
            <h1 className="text-4xl font-bold">Active Qrcodes</h1>
            <p className="text-xl">{ attributes?.qrcodes || 0}</p>
          </div>
          <div
            className="col-span-12 md:col-span-4 shadow  flex flex-col bg-green-400 rounded-xl text-white p-3 px-10">
            <h1 className="text-4xl font-bold">Withdraws </h1>
            <p className="text-xl">{ attributes?.withdraws || 0}</p>
          </div>
          <div
            className="col-span-12 md:col-span-4 shadow  flex flex-col bg-teal-400 rounded-xl text-white p-3 px-10">
            <h1 className="text-4xl font-bold">Active Users</h1>
            <p className="text-xl">{ attributes?.users || 0}</p>
          </div>
        </div>

      </Card>
      <Card>
        <DataGrid
          rows={data}
          columns={columns}
          sx={{height:"70vh"}}

          // initialState={{
          //   pagination: {
          //     paginationModel: {
          //       pageSize: 10,
          //     },
          //   },
          // }}
          pageSizeOptions={[50,100]}
          disableColumnSelector
          disableRowSelectionOnClick
          getRowId={(row) => row._id}
        />
      </Card>
    </div>
  );
};

export default Home;
