import React, { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Button, CircularProgress } from '@mui/material';
import html2canvas from 'html2canvas';

const DownloadFiles = ({ imageUrls, captureRef, setSelectedQr,ids,setSeletedId }) => {
  const [showLoader,setShowLoader] = useState(false)
  const downloadImages = async () => {
    setShowLoader(true)
    const zip = new JSZip();

    // Capture each image concurrently
    // const capturePromises = imageUrls.map(async (dataUrl, index) => {
    //   await setSelectedQr(dataUrl);
    //   await setSeletedId(ids[index]);
    //   console.log(ids[index])
    //   // Wait a moment for the image to update (if needed)
    //   await new Promise(resolve => setTimeout(resolve, 100));
    //   const canvas = await html2canvas(captureRef.current);
    //   const base64Image = canvas.toDataURL('image/png').split(',')[1];
    //   zip.file(`image${index + 1}.png`, base64Image, { base64: true });
    // });
    for (let i = 0; i < imageUrls.length;i++){
      await setSelectedQr(imageUrls[i]);
      await setSeletedId(ids[i]);
      // console.log(ids[i])
      // Wait a moment for the image to update (if needed)
      await new Promise(resolve => setTimeout(resolve, 100));
      const canvas = await html2canvas(captureRef.current);
      const base64Image = canvas.toDataURL('image/png').split(',')[1];
      zip.file(`image${i + 1}.png`, base64Image, { base64: true });
    }

    // Wait for all captures to complete
    // await Promise.all(capturePromises);
// for await (let c of capturePromises) await c;
    // Generate the zip file and trigger the download
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'images.zip');
    });
    setShowLoader(false)
  };

  return (
    <Button variant="contained" className="flex gap-2" onClick={downloadImages}>
      {showLoader && <CircularProgress color='info' className='w-12 text-white' />} Download All QR Codes
    </Button>
  );
};

export default DownloadFiles;
