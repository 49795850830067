import React, {useEffect,useState,useRef,useCallback} from 'react';
import Card from "../../../components/shared/components/Card/Card";
import DataGrid from '../../../components/shared/components/DataGridRenderer/DataGrid';
import UserService from '../../../services/User.service';
import SettingService from '../../../services/Setting.service';


 
  
const Users = () => {

    const [subscription, setSubscription] = useState()
    const gridRef = useRef(null);
 
    const [data, setData] = useState([])
    const getUserData = async () => {
      const result = await UserService.getUsers();
      if (result) {
        setData(result.data.result)
  
      }
    }
    const [setting,setSetting] = useState()
   
    const getSetting = async () => {
      const result = await SettingService.getParticular();
      if (result) {
        setSetting(result.data.result)
  
      }
    }
    useEffect(() => {
      getUserData()
      getSetting()
    }, [])
    const columns = [
      // { field: '_id', headerName: 'ID', width: 90 },
      {
        field: 'name',
        headerName: 'Name',
        width: 150,
        // editable: true,
      },
    
      {
        field: 'phone',
        headerName: 'Phone',
        // type: 'number',
        width: 150,
        // editable: true,
      },
      {
        field: 'email',
        headerName: 'Email',
        // type: 'number',
        width: 300,
        // editable: true,
      },
      {
        field: 'withDrawPoints',
        headerName: 'Withdraw',
        // type: 'number',
        width: 150,
        // editable: true,
        renderCell: (params) => <span className={`${setting?.withdraw?.minimum && (setting?.withdraw?.minimum < params.row.points?.withdraw)  ? "text-green-500" : ""}`}>{params.row.points?.withdraw}</span>
      },
      {
        field: 'tripPoints',
        headerName: 'Trips',
        // type: 'number',
        width: 150,
        // editable: true,
        renderCell: (params) => <span className={`${setting?.withdraw?.minimum && (setting?.withdraw?.minimum < params.row.points?.trip)  ? "text-green-500" : ""}`}>{params.row.points?.trip}</span>
      },
      {
        field: 'lastScanTime',
        headerName: 'Last Scan time',
        // type: 'number',
        width: 300,
        // editable: true,
        valueGetter: (value, row) => row.orders?.[0]?.createdAt ? new Date(row.orders?.[0]?.createdAt).toLocaleString() : "-"
      },
    ];
      
    return (
        <div className='flex flex-col gap-4'>
            <div className='text-3xl font-semibold'>
              Users
            </div>
           
            <Card>
               <DataGrid
          rows={data}
          columns={columns}
          sx={{height:"70vh"}}
          // initialState={{
          //   pagination: {
          //     paginationModel: {
          //       pageSize: 100,
          //     },
          //   },
          // }}
          pagination
          pageSizeOptions={[50,100]}
          disableColumnSelector
          disableRowSelectionOnClick
          getRowId={(row) => row._id}
        />
            </Card>
        </div>
    );
};

export default Users;
