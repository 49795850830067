import httpservice from "../config/httpservice";
const {  BASE_URL }=process.env;

class OfferService {
  constructor() {
    this.baseUrl=BASE_URL||"https://api.annexchemical.com";
  }
  get=async (type) => {
        return httpservice.get(this.baseUrl+"/api/offers/");
  };
  create=async (data) => {
    return httpservice.post(this.baseUrl+"/api/offers/",data);
};
update=async (id,data) => {
  return httpservice.put(this.baseUrl+"/api/offers/"+id,data);
};
changeStatus=async (id,body) => {
  return httpservice.put(this.baseUrl+"/api/offers/"+id,body);
};
delete=async (id) => {
  return httpservice.delete(this.baseUrl+"/api/offers/"+id);
};
}

export default new OfferService();
