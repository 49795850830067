import React, {useEffect,useState,useRef,useCallback} from 'react';
import Card from "../../../components/shared/components/Card/Card";
import DataGrid from '../../../components/shared/components/DataGridRenderer/DataGrid';
import BankService from '../../../services/Bank.service';
import { MenuItem, Select } from '@mui/material';
import OrderService from '../../../services/Order.service';


  
const Banks = () => {

    const [subscription, setSubscription] = useState()
    const gridRef = useRef(null);
 

    const [data, setData] = useState([])
    const getBankDetails = async () => {
      const result = await BankService.get();
      if (result) {
        setData(result.data.result)
  
      }
    }
    useEffect(() => {
      getBankDetails()
    }, [])

    const columns = [
      {
        field: 'createdAt',
        headerName: 'Date',
        width: 150,
        // editable: true,
        valueGetter: (value) => new Date(value).toLocaleDateString()
      },
      // {
      //   field: 'name',
      //   headerName: 'Name',
      //   // type: 'number',
      //   width: 150,
      //   // editable: true,
      //   valueGetter: (value, row) => row.user?.name
    
      // },
      {
        field: 'name',
        headerName: 'Account Holder Name',
        width: 300,
        valueGetter: (value, row) => row.name
      },
      {
        field: 'email',
        headerName: 'Email',
        // type: 'number',
        width: 300,
        // editable: true,
        valueGetter: (value, row) => row.user?.email
    
      },
      {
        field: 'bankName',
        headerName: 'Bank Name',
        width: 200,
        // valueGetter: (value, row) => row.name
      },
      {
        field: 'cheque',
        headerName: 'Cheque',
        width: 200,
        renderCell:(params) => <div className='flex  items-center'><a href={params.row.cheque} target='_blank'><img  src={params.row.cheque} className='w-24'></img></a></div>
        // valueGetter: (value, row) => row.name
      },
        {
          field: 'account',
          headerName: 'Account No.',
          width: 200,
          // editable: true,
        },
        {
          field: 'ifsc',
          headerName: 'IFSC',
          width: 200,
          // editable: true,
        },
        {
          field: 'status',
          headerName: 'Status',
          // type: 'number',
          width: 200,
          // editable: true,
          renderCell:  (params) => <Select className='w-full' value={params.row.status} onChange={async (e)=>{
            const fakeData = [...data];
            const index = fakeData.findIndex(i => i._id.toString() == params.row._id.toString());
            if(index > -1){
              fakeData[index].status = e.target.value;
              setData([...fakeData])
              await BankService.edit(params.row._id,{status:e.target.value})
            }
    
          }}>
            {["APPROVED",'PENDING','REJECTED'].map((item)=>{
              return <MenuItem value={item}>{item}</MenuItem>
            })}
          </Select>
        },
      ];
      
    return (
        <div className='flex flex-col gap-4'>
            <div className='text-3xl font-semibold'>
              Banks
            </div>
           
            <Card>
               <DataGrid 
                rows={data}
                columns={columns}
                sx={{height:"70vh"}}
                pagination
                pageSizeOptions={[50, 100]}
                disableColumnSelector
                disableRowSelectionOnClick
                getRowId={(row) => row._id}/>
              
            </Card>
        </div>
    );
};

export default Banks;
