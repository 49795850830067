import httpservice from "../config/httpservice";
const {  BASE_URL }=process.env;

class QRCodeService {
  constructor() {
    this.baseUrl=BASE_URL||"https://api.annexchemical.com";
  }
  get=async (type) => {
        return httpservice.get(this.baseUrl+"/api/orders/"+`?type=${type?type:""}`);
  };
  getAttributes=async () => {
    return httpservice.get(this.baseUrl+"/api/orders/attributes");
};
changeStatus=async (id,body) => {
  return httpservice.put(this.baseUrl+"/api/orders/"+id,body);
};
}

export default new QRCodeService();
