import httpservice from "../config/httpservice";
const {  BASE_URL }=process.env;

class UserService {
  constructor() {
    this.baseUrl=BASE_URL||"https://api.annexchemical.com";
  }
  getUsers=async () => {
        return httpservice.get(this.baseUrl+"/api/users");
  
   


  };
}

export default new UserService();
