import httpservice from "../config/httpservice";
const {  BASE_URL }=process.env;

class QRCodeService {
  constructor() {
    this.baseUrl=BASE_URL||"https://api.annexchemical.com";
  }
  getQrcodes=async () => {
        return httpservice.get(this.baseUrl+"/api/offers/qrcodes");
  };
  deleteQrcode=async (id) => {
    return httpservice.delete(this.baseUrl+"/api/offers/qrcode/"+id);
};
create=async () => {
  return httpservice.post(this.baseUrl+"/api/offers/qrcode/");
};
}

export default new QRCodeService();
