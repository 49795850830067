import httpservice from "../config/httpservice";
const {  BASE_URL }=process.env;

class BankService {
  constructor() {
    this.baseUrl=BASE_URL||"https://api.annexchemical.com";
  }
  get=async () => {
        return httpservice.get(this.baseUrl+"/api/banks/");
  };
  edit = async(id,data) => {
    return httpservice.put(this.baseUrl+"/api/banks/" + id, data)
  }

}

export default new BankService();
