import httpservice from "../config/httpservice";
const {  BASE_URL }=process.env;

class AuthService {
  constructor() {
    this.baseUrl=BASE_URL||"https://api.annexchemical.com";
  }
  login=async (data) => {
        return httpservice.post(this.baseUrl+"/api/users/login/email", {
            ...data
          });
  };
}

export default new AuthService();
