import React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton } from '@mui/material';
import { DownloadOutlined } from '@mui/icons-material';

const DownloadButton = (props) => {
   console.log("props.data", props.data);
    return (
        <IconButton disabled={props.disabled}
        onClick = {props.onClick}>
        <DownloadOutlined 
        
            className={`text-primary-500 ${props.cname} border border-gray-200 p-0.5 rounded-lg cursor-pointer hover:scale-110`}/>
        </IconButton>

    );
};

export default DownloadButton;
