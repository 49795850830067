import logo from './logo.svg';
import './App.css';
import { Navigate, Route, Routes } from "react-router-dom";
import LoginRoutes from './views/Login/LoginRoutes';
import Dashboard from './views/Dashboard/Dashboard';

function App() {
  return (
    <div className="">
      <Routes>
        <Route path="login/*" element={<LoginRoutes />} />
        <Route path="dashboard/*" element={<Dashboard />} />

        <Route path="" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>    </div>
  );
}

export default App;
