import axios from "axios";
import TokenHelper from "../helpers/Token.helper";
// axios.interceptors.request.use(async (request) => {
//   console.log({ request });
//   if (!request.url.includes("/api/users/update/password")) {
//     request.headers = {
//       ...request.headers,
//       Authorization: `Bearer ${TokenHelper.get()}`,
//     };
//   }
//   return request;
// });

axios.interceptors.response.use(null, (err) => {
  const expectedError =
    err.response && err.response.status >= 400 && err.response.status < 500;
  if (!expectedError) {
    // toast.error("Unexpected error occured, please Try again");
  }
  return Promise.reject(err);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
