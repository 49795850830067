import React from 'react';
import {Route, Routes} from "react-router-dom";
import Forgotpassword from "./Forgotpassword";
import Login from "./Login";
import Resetmailsent from "./ResetMailSent";
import ResetPassword from "./ResetPassword";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const LoginRoutes = (props) => {
    return (
        <section className="flex flex-col justify-center items-center h-screen w-screen  p-4">
            <ToastContainer/>
            {/* <img src="https://i.postimg.cc/y6p5vXtQ/logo-Hospital.png" alt="logo" className="my-5"/> */}
                                    <img src='/companylogotp.png' className='w-32 my-5'></img>

            <div className="max-w-3xl bg-white shadow p-5 sm:p-8 md:p-16 lg:p-20">
                <Routes>
                    <Route path="" element={<Login setUserDetails={props.handleLoginOfUser}/>}/>
                    <Route path="forgot-password" element={<Forgotpassword/>}/>
                    <Route path="mail-sent" element={<Resetmailsent/>}/>
                    <Route path="password-reset/:id" element={<ResetPassword/>}/>
                </Routes>
            </div>
        </section>
    );
};

export default LoginRoutes;