import React, { useEffect, useState, useRef, useCallback } from 'react';
import Card from "../../../components/shared/components/Card/Card";
import DataGrid from '../../../components/shared/components/DataGridRenderer/DataGrid';
import QrcodeService from '../../../services/Qrcode.service';
import { Button } from '@mui/material';
import Deletebutton from '../../../components/shared/components/Deletebutton/Deletebutton';
import DownloadButton from '../../../components/shared/components/DownloadButton/DownloadButton';
import DownloadFiles from '../../../components/shared/components/MultipleFileDownloader/MultipleFileDownloader';
import html2canvas from 'html2canvas';





const Qrcodes = () => {

  const [subscription, setSubscription] = useState()
  const gridRef = useRef(null);
  const [disableDelete, setDisableDelete] = useState(false)
  const [data, setData] = useState([])
  const captureRef = useRef(null);

  const getQRdata = async () => {
    const result = await QrcodeService.getQrcodes();
    if (result) {
      setData(result.data.result)

    }
  }
  useEffect(() => {
    getQRdata()
  }, [])
  const [number, setNumber] = useState('');
  const canvasRef = useRef(null);
  const logoRef = useRef(null);
  const [selectedQr, setSeletedQr] = useState();
  const [selectedId, setSeletedId] = useState();

  const handleDownload = async (url,id) => {
    await setSeletedQr(url)
    await setSeletedId(id)
    const canvas = await html2canvas(captureRef.current);
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/png');
    link.download = 'qrcode.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const columns = [
    {
      field: 'url',
      headerName: 'QRCode',
      width: 150,
      renderCell: (params) => <img className='w-12 h-12' src={params.value} />, // renderCell will render the component

    },
    {
      field: 'isUsed',
      headerName: 'Used',
      width: 150,
      valueGetter: (value, row) => `${row.isUsed === true ? 'Used' : "Not Used"}`,
    },
    {
      field: 'uniqueId',
      headerName: 'Unique Id',
      width: 150,
    },
    // {
    //   field: 'Action',
    //   headerName: 'Action',
    //   width: 150,
    //   renderCell: (params) => <DownloadButton disable={disableDelete} onClick={async (e)=>{
    //     e.preventDefault();
    //     const id = params.row._id;
    //     const index = data.findIndex((e) => e._id.toString() == params.row._id.toString());
    //     if(index > -1){
    //       // const demoData = [...data];
    //       // demoData.splice(index,1);
    //       // setData(demoData)
    //       try {
    //         // setDisableDelete(true)
    //         await QrcodeService.deleteQrcode(id)
    //         // setDisableDelete(false)
    //         getQRdata()
    //       } catch (error) {
    //           console.log(error)
    //           // setDisableDelete(false)

    //       }
    //     }
    //   }} data={params.row}/>, // renderCell will render the component

    // },
    {
      field: 'Action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => <DownloadButton onClick={async (e) => {
        e.preventDefault();
        const id = params.row._id;
        const index = data.findIndex((e) => e._id.toString() == params.row._id.toString());
        if (index > -1) {
          // const demoData = [...data];
          // demoData.splice(index,1);
          // setData(demoData)
          try {
            handleDownload(data[index].url,data[index].uniqueId)
            // setDisableDelete(true)
            //      // Create dummy <a> element using JavaScript.
            //  var hidden_a = document.createElement('a');

            //  // add texts as a href of <a> element after encoding.
            //  hidden_a.setAttribute('href', data[index].url);

            //  // also set the value of the download attribute
            //  hidden_a.setAttribute('download', "qrcode.png");
            //  document.body.appendChild(hidden_a);

            //  // click the link element
            //  hidden_a.click();
            //  document.body.removeChild(hidden_a);
            //     // setDisableDelete(false)
          } catch (error) {
            // console.log(error)
            // setDisableDelete(false)

          }
        }
      }} data={params.row} />, // renderCell will render the component

    },

  ];

  return (
    <div className='flex flex-col gap-4'>
      <div className='text-3xl font-semibold'>
        Qrcodes
      </div>
      <canvas ref={canvasRef} width={500} height={500} style={{ display: 'none' }}></canvas>
      <img ref={logoRef} src="/logo.png" alt="Logo" style={{ display: 'none' }} />
      <div ref={captureRef} className='absolute p-2 !w-52 bg-white z-[-10]'>
        <div className='flex w-full justify-evenly '>
          <img src="/companylogotp.png" alt="Logo" className='w-12' />
          <h6 className='p-0 m-0'>NX Chemicals</h6>
        </div>
        <div className='w-full flex justify-center items-center'>
          <img src={selectedQr} alt="QR Code" /> {/* Replace with your base64 QR code */}
        </div>
        <div className='px-2 flex justify-center items-center w-full' style={{ fontSize: '16px', color: '#000' }}>{selectedId}</div>
      </div>
      <Card className="">
        <div className='w-full flex justify-end pb-4 gap-4'>
          <Button variant='contained' onClick={async () => {
            try {
              await QrcodeService.create()
              getQRdata()
            } catch (error) {
              // console.log({ error })
            }
          }}>
            + Add New
          </Button>
          <DownloadFiles 
          imageUrls={data.filter(e => e.isUsed === false).map(e => e.url)}
          ids={data.filter(e => e.isUsed === false).map(e => e.uniqueId)}
          captureRef={captureRef}
          setSelectedQr = {setSeletedQr}
          setSeletedId={setSeletedId}
           />

        </div>

        <DataGrid
          rows={data}
          columns={columns}
          sx={{ height: "70vh" }}
          // initialState={{
          //   pagination: {
          //     paginationModel: {
          //       pageSize: 10,
          //     },
          //   },
          // }}
          pagination
          pageSizeOptions={[50,100]}
          disableColumnSelector
          disableRowSelectionOnClick
          getRowId={(row) => row._id}
        />
      </Card>
    </div>
  );
};

export default Qrcodes;
